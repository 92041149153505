import * as React from 'react'
import { Nav } from './nav'
import { OrgPositionsTable } from './table'
import { TableProvider } from './table/context/table'
import { useRailsContext } from 'components/rails-context'
import { deserialize } from '../../api/utils/deserialize'
import { OrgPositionsTableRow } from './table/data'
import { Position } from 'store/modules/positions'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

export const OrgPositionsPage = observer(() => {
  const [allLoading, setAllLoading] = React.useState(true)
  const [data, setData] = React.useState<OrgPositionsTableRow[] | null>(null)

  const { positions } = store
  const defaultRows = React.useMemo(() => {
    return positions.forOrg.map(toRow).filter(Boolean) as OrgPositionsTableRow[]
  }, [positions])

  const fetchAllPositions = async () => {
    const rows = await fetchPositions()
    setData(rows)
    setAllLoading(false)
  }

  React.useEffect(() => {
    fetchAllPositions()
  }, [])

  const { request } = useRailsContext()

  const defaultOpen = request.query.open_drawer ? true : false

  return (
    <>
      <Nav onRefresh={() => fetchAllPositions()} defaultOpen={defaultOpen} />
      <TableProvider loading={allLoading} data={data || defaultRows}>
        <OrgPositionsTable />
      </TableProvider>
    </>
  )
})

async function fetchPositions() {
  const res = await fetch('/api/v1/positions')
  if (!res.ok) return []
  const json = await res.json()
  const deserialized = deserialize(json)
  if (!deserialized?.data) return []

  const positions = deserialized.data.positions as Record<
    string,
    OrgPositionsTableRow
  >

  if (!positions) return []

  return Object.values(positions).map(normalizeRow)
}

function normalizeRow(row: OrgPositionsTableRow) {
  return {
    ...row,
    tracks: row.disciplines
      ? row.disciplines.map((discipline) => discipline.split('|')[0])
      : [],
    skillsCount: row.skills?.length || 0,
    hasSalaryData: !!row.salaries?.length,
    activeUsersCount: row.users?.length || 0,
    starredUsersCount: row.starredUsers?.length || 0,
    commentsCount: row.commentsCount || 0,
    first2StarredUserIds: row.starredUsers?.length
      ? row.starredUsers
          .slice(0, 2)
          .map((user) => (typeof user === 'string' ? user.split('|')[0] : user))
      : [],
    first2UserIds: row.users?.length
      ? row.users.slice(0, 2).map((user) => user.split('|', 1)[0])
      : [],
  }
}

function toRow(position: Position): OrgPositionsTableRow {
  return {
    id: position.id,
    name: position.name,
    teamName: '',
    tracks: position.disciplines?.map((d) => d.name) || [],
    levelCode: '',
    skillsCount: 0,
    hasSalaryData: true,
    activeUsersCount: 0,
    starredUsersCount: 0,
    commentsCount: 0,
    users: [],
    first2UserIds: [],
    first2StarredUserIds: [],
    skills: [],
    salaries: [],
    starredUsers: [],
    disciplines: [],
    imageUrl: '',
  }
}
