import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OrgPositionsTableRow } from '../data'
import { ChatTeardropText } from '@phosphor-icons/react'

export const CommentCell = observer(
  (data: CellContext<OrgPositionsTableRow, string[]>) => {
    const { commentsCount } = data.row.original

    return (
      <div className="flex items-center gap-2">
        {commentsCount > 0 ? (
          <>
            <ChatTeardropText className="h-4 text-gray-400 w-4" weight="bold" />
            <span className="font-normal text-xs">{commentsCount}</span>
          </>
        ) : (
          <span>{commentsCount}</span>
        )}
      </div>
    )
  }
)
