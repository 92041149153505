import { CellContext, ColumnDef } from '@tanstack/react-table'
import { store } from 'store/index'
import {
  NameCell,
  PeopleCell,
  StartedCell,
  CommentCell,
  TrackCell,
} from './cells'
import { formatDate } from 'app/packs/src/utils/date-helpers'

export type OrgPositionsTableRow = {
  id: string
  name: string
  teamName: string
  tracks: string[]
  skillsCount: number
  hasSalaryData: boolean
  activeUsersCount: number
  starredUsersCount: number
  commentsCount: number
  levelCode: string
  users: string[]
  first2UserIds: string[]
  skills: string[]
  salaries: string[]
  first2StarredUserIds: string[]
  starredUsers: string[]
  disciplines: string[]
  imageUrl?: string | null
}

export class Data {
  get columns() {
    return [
      {
        accessorKey: 'name',
        cell: NameCell,
        enableGlobalFilter: true,
        header: 'Name',
        id: 'name',
      },
      {
        accessorKey: 'teamName',
        cell: this.renderTeamName,
        enableGlobalFilter: true,
        header: 'Team',
        id: 'teamName',
        sortingFn: 'datetime',
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'tracks',
        header: 'Disciplines',
        enableGlobalFilter: true,
        id: 'disciplines',
        cell: TrackCell,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'levelCode',
        header: 'Level',
        enableGlobalFilter: true,
        id: 'level',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'skillsCount',
        header: 'Skills',
        enableGlobalFilter: false,
        id: 'skills',
        sortingFn: 'alphanumeric',
        filterFn: (row, columnId, filterValue) => {
          return row.getValue(columnId) === 0
        },
      },
      {
        accessorKey: 'hasSalaryData',
        header: 'Salary Data',
        enableGlobalFilter: false,
        id: 'salaryData',
        cell: ({ getValue }: CellContext<OrgPositionsTableRow, boolean>) =>
          getValue() ? 'Yes' : 'No',
        sortingFn: 'alphanumeric',
        filterFn: (row, columnId) => row.getValue(columnId) === false,
      },
      {
        accessorKey: 'activeUsersCount',
        cell: PeopleCell,
        header: 'Active Users',
        enableGlobalFilter: false,
        id: 'activeUsers',
        sortingFn: 'alphanumeric',
        filterFn: (row, columnId, filterValue) => {
          return row.getValue(columnId) === 0
        },
      },

      {
        accessorKey: 'starredUsersCount',
        cell: StartedCell,
        header: 'Starred Users',
        enableGlobalFilter: false,
        id: 'starredUsers',
        sortingFn: 'alphanumeric',
        filterFn: 'equals',
      },
      {
        accessorKey: 'commentsCount',
        cell: CommentCell,
        header: 'Comments',
        enableGlobalFilter: false,
        id: 'comments',
        sortingFn: 'alphanumeric',
        filterFn: (row, columnId) => {
          const value = row.getValue(columnId) as number
          return value > 0
        },
      },
    ] satisfies ColumnDef<OrgPositionsTableRow, never>[]
  }

  get variantsEnabled() {
    return store.featureGates.canAccess('skill_variants')
  }

  private renderTeamName(row: CellContext<OrgPositionsTableRow, string>) {
    const teamName = row.getValue()
    return teamName || ''
  }
}
