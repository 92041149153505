import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OrgPositionsTableRow } from '../data'
import { store } from 'store/index'

export const TrackCell = observer(
  (data: CellContext<OrgPositionsTableRow, unknown>) => {
    const { tracks } = data.row.original

    const trackNames = tracks
      .map((id) => store.disciplines.byId(id)?.name)
      .filter(Boolean)

    return (
      <div className="flex gap-x-2">
        {trackNames.length > 1
          ? `${trackNames[0]} +${trackNames.length - 1}`
          : trackNames[0] || ''}
      </div>
    )
  }
)
