import { Tooltip } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

export type OverviewCardProps = {
  description: string
  filterText: string
  onClick: () => void
  selected: boolean
  value: number | string
  tooltipText?: string
}

export const OverviewCard = observer((props: OverviewCardProps) => {
  const { description, onClick, selected, value, tooltipText } = props

  const button = (
    <button
      className={cn(
        'px-3 py-1.5 rounded-full border transition-colors w-max whitespace-nowrap',
        selected
          ? 'bg-green-600 text-white !border-green-600'
          : 'bg-white border-gray-100 text-gray-600'
      )}
      onClick={onClick}
    >
      {description}
      <span className="opacity-50 ml-1">{value}</span>
    </button>
  )

  return tooltipText ? (
    <Tooltip content={tooltipText}>{button}</Tooltip>
  ) : (
    button
  )
})
