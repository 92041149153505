import type { ColumnFiltersState } from '@tanstack/react-table'
import { Memoize } from 'typescript-memoize'
import stringify from 'safe-stable-stringify'
import { OrgPositionsTableRow } from './data'
import { store } from 'store/index'

export class Filters {
  constructor(private readonly data: OrgPositionsTableRow[]) {}

  get teamOptions() {
    return store.teams.sortedForCurrentUser.map((team) => ({
      label: team.name,
      value: team.name,
    }))
  }

  commentCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).filter(
      (row) => row.commentsCount > 0
    ).length
  }

  skillCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).filter(
      (row) => row.skillsCount === 0
    ).length
  }

  totalCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).length
  }

  unusedInPeopleCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).filter(
      (row) => row.activeUsersCount === 0
    ).length
  }

  nosalaryDataCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).filter(
      (row) => row.hasSalaryData === false
    ).length
  }

  @Memoize((columnFilters: ColumnFiltersState, searchFilter?: string) => {
    return `${searchFilter}-${stringify(columnFilters)}`
  })
  private filtered(columnFilters: ColumnFiltersState, searchFilter?: string) {
    return this.data.filter((row) => {
      // if (!this.rowMatchesFilters(row, columnFilters)) return false
      if (!searchFilter?.length) return true

      return !row.name.toLowerCase().includes(searchFilter.toLowerCase())
    })
  }
}
