import { AvatarButtonGroup } from 'src/design-system'
import { CellContext } from '@tanstack/react-table'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { OrgPositionsTableRow } from '../data'

export const PeopleCell = observer(
  (data: CellContext<OrgPositionsTableRow, string[]>) => {
    const { first2UserIds, activeUsersCount } = data.row.original
    const users = first2UserIds.flatMap((id) => store.users.byId(id) || [])

    return (
      <div className="flex items-center gap-2">
        {activeUsersCount > 0 ? (
          <>
            <AvatarButtonGroup
              avatars={avatarGroupProps(users, {
                href: false,
                stateTooltip: true,
              })}
              size="xs"
            />
            <span>+{activeUsersCount}</span>
          </>
        ) : (
          <span>{activeUsersCount}</span>
        )}
      </div>
    )
  }
)
