import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OrgPositionsTableRow } from '../data'

export const NameCell = observer(
  (data: CellContext<OrgPositionsTableRow, unknown>) => {
    const row = data.row.original

    return (
      <div className="flex gap-x-2 items-center">
        <div
          className="border border-white bg-image-cover flex-shrink-0 h-8 rounded w-8"
          style={{ backgroundImage: `url("${row.imageUrl}")` }}
        />

        {row.name}
      </div>
    )
  }
)
