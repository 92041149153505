import * as React from 'react'
import { OverviewCard } from './overview-card'
import { Sparkle } from '@phosphor-icons/react'

export type OverviewCardType =
  | 'comment'
  | 'total'
  | 'noactiveUser'
  | 'salary'
  | 'noskill'

export type OverviewCardsProps = {
  onClick: (overviewCardType: OverviewCardType) => void
  selectedCard: OverviewCardType
  setSelectedCard: (overviewCardType: OverviewCardType) => void
  totalCount: number
  unusedInPeopleCount: number
  nosalaryDataCount: number
  commentCount: number
  skillCount: number
}

export const OverviewCards = (props: OverviewCardsProps) => {
  const {
    onClick,
    selectedCard,
    setSelectedCard,
    totalCount,
    unusedInPeopleCount,
    nosalaryDataCount,
    commentCount,
    skillCount,
  } = props

  const selectCard = (overviewCardType: OverviewCardType) => {
    onClick(overviewCardType)
    setSelectedCard(overviewCardType)
  }

  const selected = (overviewCardType: OverviewCardType) => {
    return selectedCard === overviewCardType
  }

  return (
    <div className="flex gap-3 bg-gray-50 text-green-600 items-center p-3 overflow-x-auto">
      <Sparkle size={20} weight="fill" className="flex-shrink-0" />
      Quick filters
      <OverviewCard
        description="All Positions"
        filterText="Show all positions"
        onClick={() => selectCard('total')}
        selected={selected('total')}
        value={totalCount}
      />
      <OverviewCard
        description="No skill"
        filterText="Show positions"
        onClick={() =>
          selected('noskill') ? selectCard('total') : selectCard('noskill')
        }
        selected={selected('noskill')}
        tooltipText="Positions without any assigned skills"
        value={skillCount}
      />
      <OverviewCard
        description="No people assigned"
        filterText="Show positions"
        onClick={() =>
          selected('noactiveUser')
            ? selectCard('total')
            : selectCard('noactiveUser')
        }
        selected={selected('noactiveUser')}
        tooltipText="Positions that are not used by active users"
        value={unusedInPeopleCount}
      />
      <OverviewCard
        description="No salary data"
        filterText="Show skills"
        onClick={() =>
          selected('salary') ? selectCard('total') : selectCard('salary')
        }
        selected={selected('salary')}
        tooltipText="Positions without salary data"
        value={nosalaryDataCount}
      />
      <OverviewCard
        description="unresolved comments"
        filterText="Show positions"
        onClick={() =>
          selected('comment') ? selectCard('total') : selectCard('comment')
        }
        selected={selected('comment')}
        tooltipText="Positions with unresolved comments"
        value={commentCount}
      />
    </div>
  )
}
