import { DrawerButton } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { WithAnalytics } from 'components/with-analytics'
import * as SecondaryNav from 'components/secondary-nav'

type NavProps = {
  defaultOpen?: boolean
  onRefresh?: () => void
}

export const Nav = observer((props: NavProps) => {
  const { onRefresh, defaultOpen } = props

  const canAddPosition =
    !!store.currentUser && store.currentUser.isAdminOrEditor
  const canImportPositions = !!store.currentUser && store.currentUser.isAdmin
  const downloadLink = `/orgs/${store.currentUser?.org?.slug}/positions/download.csv`
  return (
    <>
      <SecondaryNav.Root>
        <div className="flex items-center justify-between w-full">
          <SecondaryNav.Header>Positions</SecondaryNav.Header>
          {(canAddPosition || canImportPositions) && (
            <div className="flex gap-2 items-center">
              {canImportPositions && (
                <div className="flex gap-2 items-center">
                  <a
                    className="btn btn-outline"
                    data-turbo-action="advance"
                    href={downloadLink}
                  >
                    Export CSV
                  </a>
                </div>
              )}

              {/* {canAddPosition && (
                <WithAnalytics
                  event="$track_org_secondary_nav_click"
                  params={{ button: 'Add position' }}
                >
                  <DrawerButton drawerId={''}>Create new position</DrawerButton>
                </WithAnalytics>
              )} */}
            </div>
          )}
        </div>
      </SecondaryNav.Root>
    </>
  )
})
