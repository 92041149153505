import { forRails } from 'components/for-rails'
import { RailsPositionsPage } from '../src/pages/positions-page'
import { registerComponents } from '../src/utils/react/registry'
import { PositionModalButton } from 'components/position-modal/position-modal-button'
import { OrgPositionsPage } from '../src/pages/org-position-page'

registerComponents({
  PositionsPage: forRails(RailsPositionsPage),
  PositionModalButton: forRails(PositionModalButton),
  OrgPositionsPage: forRails(OrgPositionsPage),
})
