import { Button } from 'src/design-system'
import { flexRender } from '@tanstack/react-table'
import { MinusCircle, UsersThree } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OverviewCards, OverviewCardType } from '../overview-cards'
import { SearchFilter } from 'components/search-filter'
import { Select } from 'components/atoms/select'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { useTable } from './context/table'
import * as Table from 'components/table'
import { Filters } from './filters'

export const OrgPositionsTable = observer(() => {
  const { columnFilters, data, globalFilter, setGlobalFilter, table, loading } =
    useTable()

  const source = 'org-positions-table'

  const isAdminOrEditor = !!store.currentUser?.isAdminOrEditor

  const [selectedCard, setSelectedCard] =
    React.useState<OverviewCardType>('total')

  const filterValue = (columnId: string) => {
    return columnFilters.find((column) => column.id === columnId)?.value
  }

  const filters = React.useMemo(() => new Filters(data), [data])

  const onClearFilters = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
    setSelectedCard('total')
  }

  const onFilterChange = (column: string, value: string | string[]) => {
    table.getColumn(column)?.setFilterValue(value)
    trackEvent('$track_org_position_filter_changed', { [column]: value })
  }

  const onOverviewCardClick = (overviewCardType: OverviewCardType) => {
    trackEvent('$track_org_skills_card_clicked', { card: overviewCardType })

    switch (overviewCardType) {
      case 'noactiveUser':
        table.getColumn('activeUsers')?.setFilterValue(true)
        table.getColumn('starredUsers')?.setFilterValue(undefined)
        table.getColumn('comments')?.setFilterValue(undefined)
        table.getColumn('salaryData')?.setFilterValue(undefined)
        table.getColumn('skills')?.setFilterValue(undefined)
        break
      case 'total':
        table.getColumn('activeUsers')?.setFilterValue(undefined)
        table.getColumn('starredUsers')?.setFilterValue(undefined)
        table.getColumn('salaryData')?.setFilterValue(undefined)
        table.getColumn('comments')?.setFilterValue(undefined)
        table.getColumn('skills')?.setFilterValue(undefined)
        break
      case 'salary':
        table.getColumn('activeUsers')?.setFilterValue(undefined)
        table.getColumn('starredUsers')?.setFilterValue(undefined)
        table.getColumn('comments')?.setFilterValue(undefined)
        table.getColumn('salaryData')?.setFilterValue(true)
        table.getColumn('skills')?.setFilterValue(undefined)
        break
      case 'comment':
        table.getColumn('activeUsers')?.setFilterValue(undefined)
        table.getColumn('starredUsers')?.setFilterValue(undefined)
        table.getColumn('salaryData')?.setFilterValue(undefined)
        table.getColumn('comments')?.setFilterValue(true)
        table.getColumn('skills')?.setFilterValue(undefined)
        break
      case 'noskill':
        table.getColumn('activeUsers')?.setFilterValue(undefined)
        table.getColumn('starredUsers')?.setFilterValue(undefined)
        table.getColumn('salaryData')?.setFilterValue(undefined)
        table.getColumn('comments')?.setFilterValue(undefined)
        table.getColumn('skills')?.setFilterValue(true)
    }
  }

  const showClearFiltersButton = React.useMemo(() => {
    return columnFilters.length > 0 || (globalFilter && globalFilter !== '')
  }, [columnFilters, globalFilter])

  return (
    <div>
      {isAdminOrEditor && (
        <OverviewCards
          commentCount={filters.commentCount(
            table.getState().columnFilters,
            globalFilter
          )}
          onClick={(overviewCardType) => onOverviewCardClick(overviewCardType)}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          totalCount={filters.totalCount(
            table.getState().columnFilters,
            globalFilter
          )}
          unusedInPeopleCount={filters.unusedInPeopleCount(
            table.getState().columnFilters,
            globalFilter
          )}
          nosalaryDataCount={filters.nosalaryDataCount(
            table.getState().columnFilters,
            globalFilter
          )}
          skillCount={filters.skillCount(
            table.getState().columnFilters,
            globalFilter
          )}
        />
      )}
      <div className="pt-6">
        <div className="flex flex-wrap gap-2 mb-5 px-4">
          <SearchFilter
            name="org-skills-search"
            onChange={(value) => {
              setGlobalFilter(String(value))
            }}
            value={globalFilter ?? ''}
          />
          <Select
            isMulti
            isSearchable
            onChange={(values: string[]) => onFilterChange('teamName', values)}
            options={filters.teamOptions}
            triggerContent={<TeamTriggerContent />}
            value={filterValue('teamName') as string[] | undefined}
          />

          {showClearFiltersButton && (
            <Button
              className="h-[34px]"
              onClick={onClearFilters}
              variant="outline"
            >
              <MinusCircle
                aria-hidden
                className="h-4 text-theme-40 w-4"
                weight="bold"
              />
              Clear filters
            </Button>
          )}
        </div>
        <div className="overflow-x-auto mt-10">
          <Table.Root>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Table.Header
                        key={header.id}
                        header={header}
                        loading={loading}
                        source={source}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Table.Header>
                    )
                  })}
                </Table.Row>
              ))}
            </Table.Head>

            <Table.Body>
              {table.getRowModel().rows.length === 0 && (
                <Table.NoResultsRow colspan={table.getAllColumns().length} />
              )}

              {table.getRowModel().rows.map((row, index) => {
                const cells = row.getVisibleCells()

                return (
                  <Table.Row
                    showBottomBorder={
                      index < table.getRowModel().rows.length - 1
                    }
                    key={row.id}
                  >
                    {cells.map((cell) => (
                      <Table.Cell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table.Root>
          <div className="px-3 flex justify-center">
            <Table.Pagination table={table} />
          </div>
        </div>
      </div>
    </div>
  )
})

const TeamTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <UsersThree aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All teams
    </div>
  )
}
